import(/* webpackMode: "eager", webpackExports: ["AccidentSeverityByYear"] */ "/vercel/path0/components/charts/accident-severity-by-year.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AccidentsByHour"] */ "/vercel/path0/components/charts/accidents-by-hour.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AccidentsByYearByMonth"] */ "/vercel/path0/components/charts/accidents-by-year-by-month.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AccidentsByYear"] */ "/vercel/path0/components/charts/accidents-by-year.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Fatalities"] */ "/vercel/path0/components/charts/fatalities.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Injuries"] */ "/vercel/path0/components/charts/injuries.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/common/ImageComponent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SmallContactForm"] */ "/vercel/path0/components/small-contact-form.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next-sanity@9.5.0_@sanity+client@6.22.1_@sanity+icons@3.4.0_react@18.3.1__@sanity+types@3.60._pz2cmk3abiom4u6vi3f6a6eqle/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.15_@babel+core@7.24.5_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.15_@babel+core@7.24.5_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.15_@babel+core@7.24.5_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.15_@babel+core@7.24.5_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/Accident-347.png");
